* {
    margin: 0px;
    padding: 0px;
    scroll-behavior: smooth;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  body {
    font-family: Lexend; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  main {
    position: relative;
    top: 150px;
  }
  
  .main-container {
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .content-footer_wrap {
    padding-bottom: 20rem;
  }
  
  h1 {
    font-size: 120px;
  }
  
  h2 {
    font-size: 70px;
  }
  
  h3 {
    font-size: 35px;
  }
  
  h4 {
    font-size: 20px;
  }
  
  @media only screen and (max-width: 600px) {
    .main-container {
      width: 100vw;
      margin-bottom: 100px;
    }
  
    .content-footer_wrap {
      padding-bottom: 32rem;
    }
  }
  